<script setup>
import { ref, computed } from "vue";
// import Combien from "../../../assets/ecommerce/Combien.png";
import AudioBookIcon from "../../../assets/ecommerce/audioBookIcon.png";
import Products from "./component/Products.vue";
// import { Search } from "@element-plus/icons-vue";
import router from "../../../router";
import axios from "@/gateway/backendapi";
import { useRoute } from "vue-router";
import store from "../../../store/store";
import { ElMessage } from "element-plus";
import BaseTab from "../../../components/Tab/BaseTab2.vue";
// import StarIcon from "../../../assets/ecommerce/starIcon.png";

const route = useRoute();
const singleProductItem = ref({});
// const searchQuery = ref("");
const cartItems = ref(JSON.parse(localStorage.getItem("cartItems")) || []);
// const singleProductItem = ref(JSON.parse(localStorage.getItem("singleProduct")) || {});
const tabs = ref(["Full Description", "Return Policy", "Delivery", "Product review"]);
const tenantId = computed(() => store.getters["ecommerce/getId"]);
const sessionTennantId = ref(sessionStorage.getItem("tenantId"));
// const goBack = () => {

//   window.history.back();
// };
const addToCart = () => {
  // const cartItems = ref(JSON.parse(localStorage.getItem("cartItems")) || []);
  const existingProduct = cartItems.value.find(
    (item) => item.id === singleProductItem.value.id
  );
  if (existingProduct) {
    existingProduct.quantity++;
  } else {
    cartItems.value.push({ ...singleProductItem.value, quantity: 1 });
  }
  localStorage.setItem("cartItems", JSON.stringify(cartItems.value)); // Update local storage
  store.dispatch("ecommerce/setIncrementCart");
  ElMessage({
    type: "success",
    showClose: true,
    message: "Item added cart",
    duration: 5000,
  });

  // Navigate to the cart page
  router.push("/store/cart");
};

// const addToCart = () => {
//   // Retrieve the cart items from localStorage, parse it, or use an empty array if null
//   let cartItems = JSON.parse(localStorage.getItem("cartItems")) || [];

//   // Ensure that cartItems is an array
//   if (!Array.isArray(cartItems)) {
//     cartItems = []; // If it's not an array, reset it to an empty array
//   }

//   // Add the current product to the cart
//   cartItems.push(singleProductItem.value);
//   console.log(cartItems, 'jjjj');

//   // Save the updated cart back to localStorage
//   localStorage.setItem("cartItems", JSON.stringify(cartItems));
//   store.dispatch("ecommerce/setIncrementCart")

//   ElMessage({
//       type: "success",
//       showClose: true,
//       message: "Item added cart",
//       duration: 5000,
//     });

//   // Navigate to the cart page
//   router.push("/store/cart");
// };

const getSingleProduct = async () => {
  try {
    const { data } = await axios.get(
      `/Ecommerce/GetSingleProductById?tenantid=${tenantId.value ? tenantId.value : sessionTennantId.value}&productId=${route.query.productId}`
    );
    // const { data } = await axios.get(
    //   `/Ecommerce/GetStoreInformation/${route.query.productId}`
    // );
    console.log(data, "getSingleProduct");
    singleProductItem.value = data;
  } catch (error) {
    console.log(error);
  }
};
getSingleProduct();

const reviews = ref([
  {
    text:
      "Best Book I have come across so far, I recommend you get own a copy in your Library.",
    author: "Sam Loko",
    rating: 4.5,
  },
  {
    text: "Amazing read, very insightful and engaging.",
    author: "Jane Doe",
    rating: 4.8,
  },
  {
    text: "A must-read for anyone interested in the topic.",
    author: "John Smith",
    rating: 4.7,
  },
  {
    text: "Well-written and informative. Highly recommend!",
    author: "Mary Johnson",
    rating: 4.6,
  },
  {
    text: "An excellent book with valuable information.",
    author: "James Brown",
    rating: 4.5,
  },
  {
    text: "One of the best books I've read this year.",
    author: "Patricia Williams",
    rating: 4.9,
  },
]);
</script>
<template>
  <!-- <div class="row">
    <div class="col-md-12 d-flex bg-black-2 py-4">
      <div
        class="d-flex col-md-3 align-items-center cursor-pointer justify-content-center mr-2 text-white"
        @click="goBack"
      >
        <el-icon :size="25"><Back /></el-icon>
        <span class="ml-1">Back</span>
      </div>
      <div class="col-md-6 ml-3">
        <el-input
          class="w-100 rounded-border"
          :suffix-icon="Search"
          placeholder="Search items product & services"
          v-model="searchQuery"
          size="large"
        />
      </div>
    </div>
  </div> -->
  <div class="container-slim mt-4">
    <div class="row h-100">
      <div class="col-md-6">
        <img
          :src="singleProductItem.imageURL"
          alt="Book Image"
          class="img-flud w-100 img-adjust rounded"
        />
      </div>
      <div class="col-md-6">
        <div class="col-md-12 align-items-center d-flex justify-content-between">
          <!-- <div class="s-13 fw-400 d-flex align-items-center">
            <img style="width: 20px" class="mr-1" :src="AudioBookIcon" alt="" />
            <div>AudioBook</div>
          </div> -->
          <!-- <div><i class="fas fa-heart"></i></div> -->
        </div>
        <div class="col-md-12 ">
          <div class="fw-500 s-34 text-dak">{{ singleProductItem.productName }}</div>
          <h5 class="fw-400 text-dak">NGN {{ Math.abs(singleProductItem.price).toLocaleString() }}</h5>
        </div>
        <div class="col-md-12 mt-3 d-flex justify-content-between">
          <div class="d-flex align-items-center">
            <div class="fw-400 s-24 text-grey-1">Author</div>
            <span class="s-20 fw-500 text-dak ml-2">{{ singleProductItem.author }}</span>
          </div>
        </div>
        <div class="col-md-12 mt-3">
          <div class="text-grey-1 s-24">Product Description</div>
          <div class="text-dak">{{ singleProductItem.description }}</div>
        </div>
        <div class="col-md-12 mt-3">
          <!-- <div class="input-group">
            <div
                class="input-group-prepend cursor-pointer p-2"
                @click="quantity++"
              >
                <div>+</div>
              </div>
              <div>
                <div class="border rounded px-3 py-2 mx-2">{{ quantity }}</div>
              </div>
              <div
                class="input-group-prepend cursor-pointer p-2"
                 @click="quantity > 1 ? quantity-- : 1"
              >
                <div>-</div>
              </div>
          </div> -->
        </div>
        <div class="col-md-12 mt-4">
          <div class="row">
            <div class="col-md-6" v-if="false">
              <el-button round class="w-100 py-4">
                Buy now <i class="fas fa-heart"></i>
              </el-button>
            </div>
            <div class="col-md-12">
              <el-button
                @click="addToCart"
                size="large"
                round
                color="#FF5906"
                class="mt-3 w-100 text-white py-4"
              >
                Add to Cart <i class="fas fa-shopping-cart"></i>
              </el-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row justify-content-center mt-5">
      <div class="col-md-10 mt-5">
        <BaseTab
          class="d-flex flex-wrap col-md-9 col-sm-12 justify-content-between"
          :tabs="tabs"
          :activeTabStyle="{
            color: '#ff5722',
            margin: '0 10px',
          }"
        >
          <!-- Tab Content -->
          <template #default="{ selectedTab }">
            <div
              v-if="selectedTab === 'Full Description'"
              style="line-height: 36px"
              class="row justify-content-center"
            >
              <div class="fw-500 col-md-9 col-sm-11 h5">
                {{ singleProductItem.description }}
              </div>
              <!-- <div class="mt-4 col-md-9 col-sm-11">
                nya's journey takes her through fantastical realms, from towering forests
                of candy to treacherous deserts of sand. Along the way, she encounters
                mythical creatures, wise sages, and dangerous foes. As she delves deeper
                into the labyrinth, Anya begins to realize that the dream world is not
                just a reflection of her mind, but a place where the past, present, and
                future intertwine. <br />
                With each step, Anya's memories become clearer, revealing a dark secret
                that threatens to consume her. She must confront her fears and make
                difficult choices to break free from the labyrinth and find her way back
                home.
              </div> -->
            </div>
            <div
              v-if="selectedTab === 'Return Policy'"
              style="line-height: 36px"
              class="row justify-content-center"
            >
              <div class="col-md-9 col-sm-11 fw-400">
                {{ singleProductItem.returnPolicy }}
              </div>
              <!-- <div class="mt-3 col-md-9 col-sm-11 fw-400">
                Please ensure you carefully review the book description and preview before
                making your purchase. If you have any questions or concerns, please
                contact our customer support team prior to buying.
              </div> -->
              <!-- <div class="mt-3 col-md-9 col-sm-11 fw-400">
                <p>Exceptions:</p>
                <p>
                  In the event of a technical issue preventing you from accessing or
                  reading the book, we may be able to provide a replacement copy or offer
                  a refund at our discretion. To request assistance, please contact our
                  customer support team within 30 days of your purchase. Please note that
                  technical issues related to your device or internet connection are not
                  covered under this policy.
                </p>
              </div> -->
            </div>
            <div
              v-if="selectedTab === 'Delivery'"
              style="line-height: 36px"
              class="row justify-content-center"
            >
              <!-- <div class="col-md-9 col-sm-11 fw-400">Shipping Options:</div> -->
              <div class="col-md-9 col-sm-11 fw-400">
                {{ singleProductItem.deliveryPolicy ? singleProductItem.deliveryPolicy : '' }}
              </div>
              <!-- <div class="col-md-9 col-sm-11 fw-400">
                <p>Express Shipping:</p>
                <p>
                  Available for an additional fee. Estimated delivery time is 1-2 business
                  days. International Shipping: Available to select countries. Shipping
                  rates and delivery times vary depending on the destination. Please
                  contact us for a quote.
                </p>
              </div> -->
              <!-- <div class="col-md-9 fw-400">
                <p>OrderTracking:</p>
                <p>
                  Once your order has been shipped, you will receive a tracking number via
                  email. You can use this tracking number to monitor the progress of your
                  shipment.
                </p>
              </div> -->
              <!-- <div class="col-md-9 col-sm-11 fw-400">
                <p>Delivery Delays:</p>
                <p>
                  We strive to deliver your order on time, but unforeseen circumstances
                  may occasionally cause delays. We apologize for any inconvenience this
                  may cause.
                </p>
              </div> -->
            </div>
            <div v-if="selectedTab === 'Product review'" class="">
              <div class="row">
                <div class="col-md-12">{{ singleProductItem.reviews }}</div>
              </div>
              <!-- <div class="row">
                <div
                  v-for="(review, index) in reviews"
                  :key="index"
                  class="col-md-4 mb-4"
                >
                  <div class="bg-gray-500 border-radius-8 p-3 col-md-12 h-100">
                    <div class="card-body row justify-content-between align-items-center">
                      <div class="col-md-12">
                        <p class="card-text text-dak fw-400 mb-2">{{ review.text }}</p>
                      </div>
                      <div
                        class="col-md-12 mt-4 align-items-center d-flex justify-content-between"
                      >
                        <div>
                          <p class="text-muted mb-0">{{ review.author }}</p>
                        </div>
                        <div class="d-flex align-items-center">
                          <span
                            ><img
                              class="mx-1"
                              style="height: 2rem"
                              :src="StarIcon"
                              alt=""
                          /></span>
                          <span class="text-dark h5 mb-0">{{ review.rating }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> -->
            </div>
          </template>
        </BaseTab>
      </div>
    </div>
    <div class="row mt-5 justify-content-center">
      <div class="col-md-9 col-11">
        <Products />
      </div>
    </div>
  </div>
</template>

<style scoped>
.img-adjust {
  object-fit: cover !important;
  height: auto;
}
.nav-link.active {
  color: #f47c28 !important; /* Custom color for the active tab */
  font-weight: bold;
}
</style>
