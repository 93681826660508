<script setup>
import { ref, computed } from "vue";
import ProductCard from "./ProductCard.vue";
// import ProductOneIcon from "../../../../assets/ecommerce/Temps.png";
import axios from "@/gateway/backendapi";
// import { useRoute } from "vue-router";
import { useStore } from "vuex";
import { ElMessage } from "element-plus";
import router from "../../../../router";

const allProducts = ref([]);
// const route = useRoute();
const store = useStore();
const cartItems = ref(JSON.parse(localStorage.getItem("cartItems")) || []);
const tenantId = computed(() => store.getters["ecommerce/getId"]);
console.log(tenantId.value, "tehndnt");

const getAllProduct = async () => {
  let sessionTennantId = sessionStorage.getItem('tenantId')
  try {
    const { data } = await axios.get(`/Ecommerce/GetAllProducts/${tenantId.value ? tenantId.value : sessionTennantId }`);
    console.log(data, "getAllProduct");
    allProducts.value = data;
  } catch (error) {
    console.log(error);
  }
};
getAllProduct();
const addToCart = (product) => {
  // const cartItems = ref(JSON.parse(localStorage.getItem("cartItems")) || []);
  const existingProduct = cartItems.value.find((item) => item.id === product.id);
  if (existingProduct) {
    existingProduct.quantity++;
  } else {
    cartItems.value.push({ ...product, quantity: 1 });
    
  }
  localStorage.setItem("cartItems", JSON.stringify(cartItems.value)); // Update local storage
  store.dispatch("ecommerce/setIncrementCart")
  ElMessage({
      type: "success",
      showClose: true,
      message: "Item added cart",
      duration: 5000,
    });

  // Navigate to the cart page
  router.push("/store/cart");
  
};
</script>

<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12">
      <!-- <div class="col-md-12 p-5 bg-gray-500 border-radius-8"> -->
        <!-- <div class="s-22 font-weight-600">People also View</div> -->
        <div class="s-22 font-weight-600">All Products</div>
        <div class="row">
          <div class="col-md-4 col-sm-6 col-12" v-for="product in allProducts"
          :key="product.id">
            <ProductCard
              :product="product"
              :id="product.id"
              :image="product.imageURL"
              :title="product.productName"
              :review="product.reviews"
              :price="product.price"
              @add-to-cart="addToCart"
              
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.container {
  margin-top: 20px;
}
</style>
