<template>
  <div class="p-3  bg-white">
    <img @click="productbyId" :src="image" class="w-100 imgAdjust" alt="Product Image" />
    <div class="row">
      <div class="col-md-12 cursor-pointer" @click="showSingleData">
        <div class="row">
          <div class="col-md-12 mt-3 mb-1 d-flex justify-content-between fw-500">
            <span class="s-10">E-book</span>
            <span class="s-12 fw-400">
              <!-- <img style="height: 18.9px" :src="StarBoldIcon" alt="" /> -->
              {{ review }}</span>
          </div>
          <div class="col-md-12">
            <div class="d-flex align-items-center">
              {{ title }}
            </div>
          </div>
          <div class="col-md-12">
            <hr class="w-100 mt-0 " />
          </div>
        </div>
      </div>
      <div class="col-md-12 d-flex justify-content-between align-items-center">
        <div class="text-dak font-weight-600 s-14">
          NGN {{ Math.abs(price).toLocaleString() }}
        </div>
        <div class="d-flex align-items-center">
          <el-button
            round
            color="#FF5906"
            size="small"
            class="text-dak d-flex align-items-center"
            @click="addToCart"
          >
            <img
              src="../../../../assets/tempImage/ShoppingCart.png"
              style="height: 1rem"
              alt=""
            />
            <span class="s-10 text-white fw-500">Add to Cart</span>
          </el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
// import StarBoldIcon from "../../../../assets/ecommerce/starBoldIcon.png";
import router from "../../../../router";
import { ElMessage } from "element-plus";

const props = defineProps({
  product: Object,
  id: String, // Assuming id is a number
  image: String,
  title: String,
  review: String,
  price: Number,
});
const emit = defineEmits(["add-to-cart"]);
const addToCart = () => {
  emit("add-to-cart", {
    id: props.id,
    image: props.image,
    title: props.title,
    review: props.review,
    price: props.price,
  });
  ElMessage({
    type: "success", // Changed to success to indicate a positive action
    showClose: true,
    message: `${props.title} has been added to your cart!`, // Dynamic message
    duration: 3000, // Reduced duration for a smoother UX
  });
};
const showSingleData = () => {
  console.log(props.product, "itemproduct");
  router.push(`/store/bookdetails?productId=${props.product.id}`);
  localStorage.setItem("singleProduct", JSON.stringify(props.product));
};

const productbyId = () => {
  router.push(`/store/bookdetails?productId=${props.product.id}`);
  localStorage.setItem("singleProduct", JSON.stringify(props.product));
};
</script>

<style scoped>
.card {
  margin-bottom: 20px;
}
.imgAdjust {
  height: 209px;
  object-fit: cover;
}
.imgAdjust:hover {
  transform: scale(1.1);
  transition: all 0.4s cubic-bezier(0.23, 1, 0.32, 1);
}
</style>
