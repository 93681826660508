<script setup>
import { ref, defineProps } from "vue";

const props = defineProps({
  tabs: {
    type: Array,
    required: true,
  },
  class: {
    type: String,
    default: "",
  },
  activeTabStyle: {
    type: Object,
    default: () => ({}),
  },
});

const selectedTab = ref(props.tabs[0]);

const selectTab = (tab) => {
  selectedTab.value = tab;
};
</script>

<template>
  <div class="row justify-content-center border-bottom ">
    <div :class="props.class" class="border-botom d-flex">
      <div
        v-for="tab in tabs"
        :key="tab"
        class="position-relative mb-2 cursor-pointer"
        @click="selectTab(tab)"
      >
        <span
          :style="tab === selectedTab ? activeTabStyle : {}"
          :class="['pb-2', tab === selectedTab ? 'active-tab' : 'fw-400']"
          class="d-flex align-items-center justify-content-center"
        >
          {{ tab }}
        </span>
      </div>
    </div>
  </div>

  <div class="tab-content mt-3 p-3">
    <slot :selectedTab="selectedTab"></slot>
  </div>
</template>

<style scoped>
.active-tab {
  position: relative;
  font-weight: 500;
}

.active-tab::after {
  content: "";
  position: absolute;
  bottom: -10.5px;
  left: 0;
  width: 100%;
  height: 4px;
  background: currentColor;
  border-radius: 8px;
}

.border-botom {
  border-bottom: 3px solid #e9e9e9 !important;
}

@media screen and (max-width: 452px) {
  .border-botom {
    border-bottom: 0 !important;
  }
}
</style>
